import React, { Component, Fragment } from 'react';
import Header from '../Hearder/Header';

class TermOfUse extends Component {
  render() {
    return (
      <Fragment>
        <div className='term-service'>
          <Header />
          <main>
            <h2>Terms of service</h2>
            <article>
              <h3>DESCRIPTION OF SERVICE</h3>
              <p>Social.gg currently provides users with the trend on Social Media a.k.a Facebook. You understand and agree that the Service is provided “AS-IS” and that Social.gg assumes no responsibility for the collecting, storing, deleting, combining and disclosing information.</p>
              <p>In order to use the Service, you must obtain access to the World Wide Web, either directly or through devices that access web-based content. In addition, you must provide all equipment necessary to make such connection to the World Wide Web, including a computer and modem or other access device.</p>
              <ul>
                <li>Social.gg are not affiliated and have nothing related with Facebook Inc.</li>
                <li>Social.gg reserves the full authority to select Pages and Categories and has the right to delete inappropriate Pages showing consequence without cause or notice to Facebook Page owner.</li>
                <li>Social.gg does not guarantee collecting and sorting into the correct category for every post of pages.</li>
                <li>Every displayed post from each Facebook page does not belong to the Social.gg’s ownership. Social.gg takes no reasonability and assumes no liability for any post that each Facebook page posts.</li>
                <li>Social.gg may contain links to third-party websites, advertisers, services, special offers, or other event or activities that are not owned or controlled by Social.gg. We do not endorse or assume any responsibility for any such third-party sites, information, material, products or services. If you access any third party website, service or content from Social.gg, you do so at your own risk and you agree that Social.gg will have no liability arising from your use of or access to any third-party website, service, or content.</li>
              </ul>
              <h3>COMMERCIAL USE</h3>
              <p>Unless otherwise expressly authorized by Social.gg, you agree not to display, distribute, license, perform, publish, reproduce, duplicate, copy, create derivative works from, modify, sell, resell, exploit, transfer or upload for any commercial purposes, any portion of the Services, use of the Services or access to the Services. Unless otherwise expressly agreed by Social.gg, the Services are for your personal use.</p>
              <p>In the performance of Social.gg function under this Terms of Service, Social.gg will require to collect, store, use and the Personal Identifiable Information of the relevant User. For your information about Social.gg Privacy Policy, please read our Privacy Policy at https://Social.gg.com/privacy-policy This policy explains how Social.gg treat your personal identifiable information and protects your privacy when you use Social.gg.</p>
            </article>
          </main>
        </div>
      </Fragment>
    );
  }
}

export default TermOfUse;
