import * as _ from 'lodash';
import { SAVE_STREAM } from './../actions/Types';

const initialState = {
    stream: [],
};

/**
 *
 *
 * @export
 * @param {*} [state=initialState]
 * @param {{ payload: any, type: string }} action
 * @returns
 */
export default function StreamsReducer(state = initialState, action: { payload: any, type: string }) {
    switch (action.type) {
        case SAVE_STREAM:
            return { ...state, stream: action.payload };
        default:
            return state;
    }
}
