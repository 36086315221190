import { ICategory, ICategoryDetail } from '../sheard/interfaces/Category';
import { SAVE_CATEGORIES, SELECT_CATEGORY } from './Types';

/**
 *
 *
 * @export
 * @param {ICategory[]} categories
 * @returns {{ payload: ICategory[], type: string }}
 */
export function saveCategory(categories: ICategory[]): { payload: ICategory[], type: string } {
    return {
        type: SAVE_CATEGORIES,
        payload: categories,
    };
}

/**
 *
 *
 * @export
 * @param {ICategory} category
 * @returns {{ payload: ICategory, type: string }}
 */
export function selectCategory(category: ICategoryDetail): { payload: ICategoryDetail, type: string } {
    return {
        type: SELECT_CATEGORY,
        payload: category,
    };
}
