import * as _ from 'lodash';
import { LOADING_INIT, LOADING_MORE } from './../actions/Types';

const initialState = {
    loadingInit: true,
};

/**
 *
 *
 * @export
 * @param {*} [state=initialState]
 * @param {{ payload: any, type: string }} action
 * @returns
 */
export default function loading(state = initialState, action: { payload: any, type: string }) {

    switch (action.type) {
        case LOADING_INIT:
            return { ...state, loadingInit: action.payload };
        case LOADING_MORE:
            return { ...state, loadingMore: action.payload };
        default:
            return state;
    }
    return state;
}
