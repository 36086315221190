import axios from 'axios';
import * as _ from 'lodash';
import iconCategories from './../../sheard/functions/icon-categories';
import { ICategory } from './../../sheard/interfaces/Category';
import { Api } from './../api/api';

export function getCategories(): Promise<void | ICategory[]> {
    return Api.get({
        path: '/api/category',
    }).then((response: { status: number, data: { data: ICategory[] } }) => {
        if (response.status === 200) {
            return response.data.data;
        } else {
            throw new Error('Error getCategories not status 200');
        }

    })
        .then((value: ICategory[]) => {
            return value.filter((itemCountries: ICategory) => {
                return itemCountries.categories.filter((category: any) => {
                    return _.assign(category, { icon: iconCategories(category.permalink === '' ? 'all' : category.permalink) });
                });
            });
        })
        .then((value: ICategory[]) => {
            return value.filter((itemCountries: ICategory) => {
                // tslint:disable-next-line:max-line-length
                return _.remove(itemCountries.categories, (category: any) => category.permalink === 'hidden' || category.permalink === 'organization');

            });
        })
        .catch((error: any) => {
            // tslint:disable-next-line:no-console
            console.log(error);
        });
}
