// import Tracker from '@openreplay/tracker';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppRouter from './AppRouter';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermOfUse from './components/TermOfUse/TermOfUse';

interface IProps {
  store: any;
}

const initializeTokenFromSe4 = () => {
  const params: any = new URLSearchParams(window.location.search);
  const token = params.get('token');
  if (token) {
    localStorage.setItem('token', params.get('token'));
  }
};

class App extends Component<IProps, {}> {
  render() {
    const { store } = this.props;
    ReactGA.initialize('UA-12086725-13');
    // tslint:disable-next-line:no-console
    if (window.location.pathname !== '/') {
      ReactGA.pageview(window.location.pathname);
    }

    initializeTokenFromSe4();

    // const tracker = new Tracker({
    //   projectKey: 'aQjT4w5aAMcHsDaC9LTZ',
    // });
    // tracker.start();

    return (
      <BrowserRouter>
        <Provider store={store}>
          <Switch>
            <Route
              exact
              path='/privacy-policy'
              render={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
              exact
              path='/terms-of-use'
              render={(props) => <TermOfUse {...props} />}
            />
            <Route
              exact
              path='*'
              render={(props) => <AppRouter store={store} {...props} />}
            />
          </Switch>
          {/* <AppRouter store={store} /> */}
        </Provider>
      </BrowserRouter>
    );
  }
}

export default App;
