export const Name_categories: any = {
    '': 'ALL',
    'advertising': 'ADVERTISING',
    'auto': 'AUTO',
    'automobile': 'AUTOMOBILE',
    'beauty': 'BEAUTY',
    'bank-financial-institution': 'BANK/FINANCIAL INSTITUTION',
    'brands': 'BRANDS',
    'digital-tv': 'DIGITAL-TV',
    'drink-alc': 'DRINK(ALC)',
    'e-commerce': 'E-COMMERCE',
    'education': 'EDUCATION',
    'entertainment': 'ENTERTAINMENT',
    'financial': 'FINANCIAL',
    'food': 'FOOD',
    'government': 'GOVERNMENT',
    'health-sport': 'HEALTH&SPORT',
    'healths': 'HEALTHS',
    'hospital': 'HOSPITAL',
    'inspiration': 'INSPIRATION',
    'media': 'MEDIA',
    'news': 'NEWS',
    'others': 'OTHERS',
    'people-blogger': 'PEOPLE/BLOGGER',
    'politics': 'POLITICS',
    'promotion': 'PROMOTION',
    'real-estate': 'REAL-ESTATE',
    'restrict': 'RESTRICT',
    'shops-retails': 'SHOPS/RETAILS',
    'sport': 'SPORT',
    'technology': 'TECHNOLOGY',
    'telecommunication': 'TELECOMMUNICATION',
    'travel': 'TRAVEL',
    'variety': 'VARIETY',
    'website': 'WEBSITE',
    'youtuber': 'YOUTUBER',
    'mkt': 'MKT',
    'mom-kids': 'MOM&KIDS',
    'movie': 'MOVIE',
    'youtuberxinfluencer': 'YOUTUBERxINFLUENCER',
    'laos': 'Laos'
};

const nameCategory = (name: string): string => {
    return Name_categories[name];
};

export default nameCategory;
