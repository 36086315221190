import { CLOSE_DIALOG_CATEGORY, OPEN_DIALOG_CATEGORY } from './../actions/Types';

const initialState = {
    dialogName: null,
};

/**
 *
 *
 * @export
 * @param {*} [state=initialState]
 * @param {{ payload: any, type: string }} action
 * @returns
 */

export default function DialogReducer(state = initialState, action: { payload: any, type: string }) {
    switch (action.type) {
        case OPEN_DIALOG_CATEGORY:
        case CLOSE_DIALOG_CATEGORY:
            return { ...state, dialogName: action.payload };
        default:
            return state;
    }
    return state;
}
