import React from 'react';

export const NoData = () => {
  return (
    <div style={{alignItems: 'center', height: '100%', display: 'flex', justifyContent: 'center'}}>
    <div className='no-data show-flex' style={{height: '0px'}}>
      <article>
        <i className='fas fa-database' />
        <p>Data not found</p>
      </article>
    </div>
  </div>
);
};

export default NoData;
