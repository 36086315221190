import { LOADING_INIT, LOADING_MORE } from './Types';

/**
 *
 *
 * @export
 * @param {boolean} status
 * @returns {{ payload: { loadingInit: boolean }, type: string }}
 */
export function loadingInit(status: boolean): { payload: boolean, type: string } {
    return {
        type: LOADING_INIT,
        payload: status,
    };
}

/**
 *
 *
 * @export
 * @param {boolean} status
 * @returns {{ payload: boolean, type: string }}
 */
export function loadingMore(status: boolean): { payload: boolean, type: string } {
    return {
        type: LOADING_MORE,
        payload: status,
    };
}
