import React from 'react';
import { colorCode } from '../../../sheard/functions/color-code';
import PieChart from '../Echarts/PieChart';
import { IReportTrendBySocialMedia } from '../ReportTrend';
import blogLogo from './../../../assets/images/social-media/blog-news.png';
import facebookLogo from './../../../assets/images/social-media/facebook.png';
import instagramLogo from './../../../assets/images/social-media/instagram.png';
import tiktokLogo from './../../../assets/images/social-media/tiktok.png';
import webboardLogo from './../../../assets/images/social-media/webboard.png';
import xLogo from './../../../assets/images/social-media/x-twitter.png';
import youtubeLogo from './../../../assets/images/social-media/youtube.png';

const CircularProgressChart = (props: { data: IReportTrendBySocialMedia }) => {
  const { data } = props;
  return (
    <section className='naked'>
      <div className='chart-container group-chart'>
        {getOptions(data).map((option: any) => {
          return (
            <>
              <div
                className='chart-social'
                style={{ height: '250px', width: '14.2%' }}
              >
                <PieChart chartOption={option} />
                <img src={option.logo} />
              </div>
            </>
          );
        })}
      </div>
    </section>
  );
};

const getOptions = (data: IReportTrendBySocialMedia) => {
  const socialMediaKeys = Object.keys(data);
  const _data: any = data;
  let countAll = 0;
  socialMediaKeys.forEach((socialMediaKey: string) => {
    countAll += parseInt(_data[socialMediaKey], 10) || 0;
  });

  return [
    {
      logo: facebookLogo,
      pie_data: [{ value: data.facebook || 0, name: 'Facebook' }],
      data_color: [colorCode.facebook],
      min: 0,
      max: countAll,
    },
    {
      logo: xLogo,
      pie_data: [{ value: data.twitter || 0, name: 'X' }],
      data_color: [colorCode.twitter],
      min: 0,
      max: countAll,
    },
    {
      logo: instagramLogo,
      pie_data: [
        {
          value: data.instagram || 0,
          name: 'Instagram',
        },
      ],
      data_color: [colorCode.instagram],
      min: 0,
      max: countAll,
    },
    {
      logo: youtubeLogo,
      pie_data: [{ value: data.youtube || 0, name: 'Youtube' }],
      data_color: [colorCode.youtube],
      min: 0,
      max: countAll,
    },
    {
      logo: webboardLogo,
      pie_data: [{ value: data.webboard || 0, name: 'Webboard' }],
      data_color: [colorCode.webboard],
      min: 0,
      max: countAll,
    },
    {
      logo: blogLogo,
      pie_data: [
        {
          value: data.website || 0,
          name: 'News & Blog',
        },
      ],
      data_color: [colorCode.blog],
      min: 0,
      max: countAll,
    },
    {
      logo: tiktokLogo,
      pie_data: [{ value: data.tiktok || 0, name: 'Tiktok' }],
      data_color: [colorCode.black],
      min: 0,
      max: countAll,
    },
  ];
};

export default CircularProgressChart;
