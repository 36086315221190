// import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CategoriesDialog from './CategoriesDialog';

interface IProps {
    dialogName: string;
}

interface IState {
    dialog: {
        dialogName: string;
    };
}

class Dialog extends Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    render() {
        if (!this.props.dialogName) {
            return (
                <div></div>
            );
        }
        if (this.props.dialogName === 'category') {
            return (
                <div className='overlay show-flex'>
                    <CategoriesDialog />
                </div>
            );
        }
    }
}

const mapStateToProps = (state: IState) => {
    return {
        dialogName: state.dialog.dialogName,
    };
};

export default connect(mapStateToProps, null)(Dialog);
