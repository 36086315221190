import createHistory from 'history/createBrowserHistory';
import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxPromise from 'redux-promise';
import thunkMiddleware from 'redux-thunk';
import App from './App';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';
import { Api } from './sheard/api/api';
import './styles/style.scss';

// tslint:disable-next-line:align
// tslint:disable-next-line:no-unused-expression
new Api();
// Redux dev tool
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunkMiddleware, ReduxPromise)),
);

ReactDOM.render(
    <App store={store} />,
    document.getElementById('root'));

serviceWorker.unregister();
