import React, { useEffect, useRef } from 'react';

const EchartComponent = (props: { options: any }) => {
  const chartRef = useRef(null);
  const { options } = props;

  useEffect(() => {
    const _window: any = window;
    if (_window.echarts && chartRef.current) {
      const chart = _window.echarts.init(chartRef.current);
      chart.setOption(options);
    }
  }, [options]);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default EchartComponent;
