import React, { Component } from 'react';
import logo from './../../assets/images/images_logo-large.png';
class Header extends Component {

    render() {
        return (
            <header className='header'>
                <div className='container'>
                    <a className='logo' href='/thailand/trend/'><img src={logo} alt='' /></a>
                </div>
            </header>
        );
    }
}

export default Header;
