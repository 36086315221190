
export const Icon_By_Permalink: any = {
    'all': 'fas fa-comments',
    'auto': 'fas fa-tachometer-alt',
    'bank-financial-institution': 'fas fa-money-bill-alt',
    'brands': 'fas fa-money-bill-alt',
    'e-commerce': 'fas fa-money-bill-alt',
    'education': 'fas fa-money-bill-alt',
    'entertainment': 'fas fa-video',
    'healths': 'fas fa-video',
    'inspiration': 'fas fa-magic',
    'media': 'fas fa-tablet-alt',
    'news': 'fas fa-rss-square',
    'others': 'fas fa-bookmark',
    'people-blogger': 'far fa-meh',
    'politics': 'fas fa-gavel',
    'restrict': 'fas fa-minus-circle',
    'shops-retails': 'fas fa-home',
    'sport': 'fas fa-trophy',
    'technology': 'fas fa-laptop',
    'telecommunication': 'fas fa-phone',
    'travel': 'fas fa-plane',
    'variety': 'fas fa-users',
    'website': 'fas fa-globe-asia',
};

const iconCategories = (name: string): string => {
    return Icon_By_Permalink[name];
};

export default iconCategories;
