import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { converToNumberFormat } from '../../../sheard/functions/numeral';
import NoData from '../NoData';
import { ITopUser } from '../ReportTrend';
import facebookImg from './../../../assets/images/social-media/facebook.png';

const TopUser = (props: { data: ITopUser[] }) => {
  const { data } = props;
  const [isNoData, setIsNoData] = useState(false);

  useEffect(() => {
    if (!data || data.length === 0) {
      setIsNoData(true);
    } else {
      setIsNoData(false);
    }
  }, [data]);

  return (
    <section>
      <div className='title'>
        <h3>Top users</h3>
      </div>
      <ul className='user-list'>
        {isNoData ? <NoData /> : data.map((topUser: ITopUser) => {
          return (
            <li>
              <ReactTooltip place='bottom' type='dark' effect='float'  />
              <img src={topUser.avatar_url} alt='' />
              <article>
                <div className='name'>
                  <img src={facebookImg} alt='' />
                  <p>{topUser.name}</p>
                </div>
                <ul className='engagement'>
                  <li data-tip={`Engagement ${converToNumberFormat(topUser.engagement)}`}>
                    <i className='fa fa-bolt'></i>
                    <p>{converToNumberFormat(topUser.engagement)}</p>
                  </li>
                  <li data-tip={`Post ${converToNumberFormat(topUser.count)}`}>
                    <i className='fa fa-comment'></i>
                    <p>{converToNumberFormat(topUser.count)}</p>
                  </li>
                  <li data-tip={`Follower ${converToNumberFormat(topUser.followers)}`}>
                    <i className='fa fa-users'></i>
                    <p>{converToNumberFormat(topUser.followers)}</p>
                  </li>
                </ul>
              </article>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default TopUser;

// interface ITopUser {
//   name: string;
//   channel_type: string;
//   engagement_count: number;
//   comment_count: number;
//   follower_count: number;
//   avatar_url: string;
// }

// const getData = (): ITopUser[] => {
//   return [
//     {
//       name: "Alex Turner",
//       channel_type: "facebook",
//       engagement_count: 30000,
//       comment_count: 1500,
//       follower_count: 1000,
//       avatar_url: avatar1,
//     },
//     {
//       name: "Alex Turner",
//       channel_type: "facebook",
//       engagement_count: 30000,
//       comment_count: 1500,
//       follower_count: 1000,
//       avatar_url: avatar1,
//     },
//     {
//       name: "Alex Turner",
//       channel_type: "facebook",
//       engagement_count: 30000,
//       comment_count: 1500,
//       follower_count: 1000,
//       avatar_url: avatar1,
//     },
//     {
//       name: "Alex Turner",
//       channel_type: "facebook",
//       engagement_count: 30000,
//       comment_count: 1500,
//       follower_count: 1000,
//       avatar_url: avatar1,
//     },
//     {
//       name: "Alex Turner",
//       channel_type: "facebook",
//       engagement_count: 30000,
//       comment_count: 1500,
//       follower_count: 1000,
//       avatar_url: avatar1,
//     },
//   ];
// };
