/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the default styles
import { convertDateTypeToTimeRanges } from '../../sheard/functions/date-type-to-ranges';

const dateTimeLists = [
  {label: 'Yesterday', value: 'yd'},
  {label: 'Last 7 days', value: 'l7'},
  {label: 'Last 14 days', value: 'l14'},
  {label: 'This month', value: 'tm'},
  {label: 'Last month', value: 'lm'},
  {label: 'Most recent', value: 'mr'},
  // {label: 'Define period', value: 'df'},
];

const DateTime = (props: {handleDateChange: any}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateType, setDateType] = useState('yd');
  const [selected, setSelected] = useState<any>(null);
  const [startDate , setStartDate] = useState<any>(new Date());
  const [endDate , setEndDate] = useState<any>(new Date());

  const isShowDefinePeriod = false;
  const elementRef: any = useRef(null);

  const openDateTime = () => setIsOpen(true);
  const closeDateTime = () => setIsOpen(false);

  const changeDateTime = (type: string) => {
    setDateType(type);
    onSubmit(type);
  };

  const handleClickOutside = (event: any) => {
    // Check if click is outside the elementRef
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      closeDateTime();
    }
  };

  const dateTimeLabel = () => {
    const dateTimeList = _.find(dateTimeLists, ['value', dateType]);
    return dateTimeList ? dateTimeList.label : '-';
  };

  const toggleClassDropdown = isOpen ? 'filter-dropdown show-filter-dropdown' : 'filter-dropdown';

  useEffect(() => {
    // Add mousedown event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleStartTimeChange = (date: any) => {
    setStartDate(date);

    // If endTime is before the new startTime, reset endTime
    if (endDate && date >= endDate) {
      // setEndDate(null);
    }
  };

  const handleEndTimeChange = (date: any) => {
    // Only set endTime if it's after startTime
    if (startDate && date < startDate) {
      alert('End time cannot be before start time!');
      return;
    }
    setEndDate(date);
  };

  const onSubmit = (type: string) => {
    let startDateUnxi;
    let endDateUnix;
    if (type === 'df') {
      startDateUnxi = moment(startDate).unix();
      endDateUnix = moment(endDate).unix();
    } else {
      const dateTime = convertDateTypeToTimeRanges(type);
      startDateUnxi = dateTime.start_date;
      endDateUnix = dateTime.end_date;
    }
    props.handleDateChange(startDateUnxi, endDateUnix);
    closeDateTime();
  };

  return (
    <div
      ref={elementRef}
      className={toggleClassDropdown}
    >
      <a onClick={openDateTime}>{dateTimeLabel()}<i className='la la-angle-left'></i></a>
      <ul>
        {dateTimeLists.filter((dateTimeList: any) => dateTimeList.value !== dateType && dateTimeList.value !== 'df')
        .map((dateTimeList: any) => {
          return (<li><a onClick={() => changeDateTime(dateTimeList.value)}>{dateTimeList.label}</a></li>);
        })}
        {isShowDefinePeriod && <li>
          <a className='open-define-period' onClick={() => setDateType('df')}>Define period</a>
          {dateType === 'df' && (
            <div className='define-period show-define-period'>
              <label className='text-icon'>
                <i className='fa fa-calendar'></i>
                <DatePicker maxDate={endDate}
                  placeholderText='Start date'
                  selected={startDate}
                  onChange={(date: any) => handleStartTimeChange(date)}
                  dateFormat='dd-MM-YYYY'
                />
              </label>
              <label className='text-icon'>
                <i className='fa fa-calendar'></i>
                <DatePicker
                  minDate={startDate}
                  maxDate={new Date()}
                  placeholderText='End date'
                  selected={endDate}
                  onChange={(date: any) => handleEndTimeChange(date)}
                  dateFormat='dd-MM-YYYY'
                  />
              </label>
              <button className='btn-mini' onClick={() => onSubmit('df')}>Submit</button>
            </div>
          )}
        </li>}
      </ul>
    </div >
  );
};

export default DateTime;
