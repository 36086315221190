import newAPIClient from '../api/newApi';

const isMockAPI = process.env.REACT_APP_MOCK_API === '1';

const mapQueryParam = (url: string, params: any) => {
  const queryString = new URLSearchParams(params).toString();
  return isMockAPI ? url : `${url}?${queryString}`;
};

export const getReportTrend = (params: any): Promise<void | any> => {
  const urlQueryString = mapQueryParam('report', params);
  return newAPIClient.get(urlQueryString).then((res: any) => {
    return res.data;
  });
};

export const getTopics = (): Promise<void | any> => {
  return newAPIClient.get('report/topics').then((res: any) => {
    return res.data;
  });
};

export const getWeeklyHeatmap = (params: any): Promise<any> => {
  const urlQueryString = mapQueryParam('report/weekly_heatmap', params);
  return newAPIClient.get(urlQueryString).then((res: any) => {
    return res.data;
  });
};
