import * as _ from 'lodash';
import { SAVE_CATEGORIES, SELECT_CATEGORY } from './../actions/Types';

const initialState = {
    categories: [],
    categorySelected: {
        name: 'ALL',
        is_disable: false,
        permalink: '',
        private: false,
        min_age: 0,
        is_authen: false,
        fontawesome_icon: 'fa-comments-o',
        bgcolor: '#4cb07c',
        icon: 'fas fa-comments',
    },
};

/**
 *
 *
 * @export
 * @param {*} [state=initialState]
 * @param {{ payload: any, type: string }} action
 * @returns
 */
export default function CategoriesReducer(state = initialState, action: { payload: any, type: string }) {
    switch (action.type) {
        case SAVE_CATEGORIES:
            return { ...state, categories: action.payload };
        case SELECT_CATEGORY:
            return { ...state, categorySelected: action.payload };
        default:
            return state;
    }
}
