import { IStream } from './../sheard/interfaces/Stream';
import { SAVE_STREAM } from './Types';

/**
 *
 *
 * @export
 * @param {IStream[]} streams
 * @returns {{ payload: IStream[], type: string }}
 */
export function saveStreams(streams: IStream[]): { payload: IStream[], type: string } {
    return {
        type: SAVE_STREAM,
        payload: streams,
    };
}
