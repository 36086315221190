import * as _ from 'lodash';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCategory } from '../../actions/Categories';
import { saveCategory } from '../../actions/Categories';
import { openDialogCategory } from '../../actions/Dialog';
import { selectSortType } from '../../actions/SortStrem';
import { saveStreams } from '../../actions/Stream';
import history from '../../history';
import { ICategory, ICategoryDetail } from '../../sheard/interfaces/Category';
import { IStream } from '../../sheard/interfaces/Stream';
import { getCategories } from '../../sheard/services/CategoriesService';
import { getStreams } from '../../sheard/services/StreamService';
import { ITopic } from '../Report/ReportTrend';

interface IProps {
  openCategory: typeof openDialogCategory;
  saveCategories: typeof saveCategory;
  categorySelected: ICategoryDetail;
  savedStreams: typeof saveStreams;
  streams: IStream[];
  selectedSortType: typeof selectSortType;
  sort_type: string;
  categories?: ICategory[];
  selectedCategory: typeof selectCategory;
  topics?: ITopic[];
  type?: string;
  onSelectTopic?: any;
}

interface IState {
  openCategory: typeof openDialogCategory;
  category: {
    categorySelected: ICategoryDetail;
    categories: ICategory[];
  };
  stream: { stream: IStream[] };
  sort_stream: { sort_type: string };
}

class Category extends Component<
  IProps,
  { categories?: any; topics?: ITopic[]; type?: string; onSelectTopic?: any }
> {
  public typeCategorySelected = '';
  public typeTopicSelected = '';
  public isShowMore = false;

  constructor(props: any) {
    super(props);
  }
  componentWillMount() {
    if (
      (this.props.categories && this.props.categories.length > 0) ||
      this.props.type === 'topic'
    ) {
      return;
    }
    getCategories().then((value: void | ICategory[]) => {
      if (value) {
        this.setState({ categories: value });
      }
    });
    const path = window.location.pathname
      ? window.location.pathname.split('/')
      : [];
    this.typeCategorySelected = path[3] ? path[3] : '';
    this.props.selectedSortType(path[2] ? path[2] : 'trend');
    this.props.selectedCategory({ permalink: path[3] });
    // tslint:disable-next-line:no-unused-expression
    history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });
  }

  componentDidMount() {
    const timer = setInterval(() => {
      const elementCurrentNav = document.querySelector(
        '.current',
      ) as HTMLElement;
      const scroll = document.getElementById('inner-scroll');
      if (
        elementCurrentNav &&
        elementCurrentNav.offsetLeft > window.screen.width &&
        scroll
      ) {
        scroll.scroll(elementCurrentNav.offsetLeft, 0);
        clearInterval(timer);
      }
    }, 200);
  }

  /**
   *
   *
   * @param {ICategoryDetail} category
   * @param {string} path
   * @memberof Category
   */
  public selected(category: ICategoryDetail, path: string) {
    history.push(path);
    this.props.selectedCategory(category);
    this.typeCategorySelected = category.permalink;
    this.forceUpdate();
  }

  /**
   *
   *
   * @param {string} type
   * @returns
   * @memberof Category
   */
  public checkCurrentSelect(type: string) {
    return this.typeCategorySelected === type;
  }

  public checkTopicCurrentSelect(id: string) {
    return this.typeTopicSelected === id;
  }

  /**
   *
   *
   * @returns
   * @memberof Category
   */
  public renderList() {
    if (
      this.state &&
      this.state.categories &&
      this.state.categories.length > 0
    ) {
      return this.state.categories[0].categories.map((category: any) => {
        const path = `/thailand/${this.props.sort_type}/${category.permalink}`;
        return (
          <a
            className={
              this.checkCurrentSelect(category.permalink) ? 'current' : ''
            }
            onClick={() => {
              this.selected(category, path);
            }}
            key={category.name}
          >
            {category.name}
          </a>
        );
      });
    }
  }

  renderTopicList() {
    if (this.props.topics && this.props.topics.length > 0) {
      if (!this.typeTopicSelected) {
        this.typeTopicSelected = this.props.topics[0]._id;
        this.props.onSelectTopic(this.props.topics[0]);
      }
      return this.props.topics.map((topic: ITopic) => {
        return (
          <a
            className={this.checkTopicCurrentSelect(topic._id) ? 'current' : ''}
            onClick={() => {
              this.typeTopicSelected = topic._id;
              this.props.onSelectTopic(topic);
              this.forceUpdate();
            }}
            key={topic._id}
          >
            {topic.name}
          </a>
        );
      });
    }
  }

  render() {
    return (
      <div className='select-category'>
        <div className='container'>
          <nav id='inner-scroll' className={this.isShowMore ? 'show-more' : ''}>
            {this.props.type === 'topic'
              ? this.renderTopicList()
              : this.renderList()}
            <a
              className={this.isShowMore ? 'show-more active' : 'show-more'}
              onClick={() => {
                this.isShowMore = !this.isShowMore;
                this.forceUpdate();
              }}
            >
              <i className='fa fa-chevron-down'></i>
            </a>
          </nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return {
    categorySelected: state.category.categorySelected,
    streams: state.stream.stream,
    sort_type: state.sort_stream.sort_type,
    categories: state.category.categories,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      openCategory: openDialogCategory,
      saveCategories: saveCategory,
      savedStreams: saveStreams,
      selectedSortType: selectSortType,
      selectedCategory: selectCategory,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
