import React, { CSSProperties } from 'react';
import { MoonLoader } from 'react-spinners';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
};

export const LoadingContainer = () => {
  return (
    <div className='loading-box' style={{height: '100%'}}>
      <MoonLoader color='gray' cssOverride={override} size={25} />
    </div>
  );
};

const LoadingBox = (props: {title?: string}) => {
  return (
    <section>
      <div className='title'>
        <h3>{props.title || 'Loading data'}</h3>
      </div>
      <div className='chart-container' style={{height: '100%'}}>
        <LoadingContainer />
      </div>
    </section>
  );
};

export default LoadingBox;
