import * as _ from 'lodash';
import { ERROR_FOR_SHOW, NO_DATA } from './../actions/Types';

const initialState = {
  foundError: null,
};

/**
 *
 *
 * @export
 * @param {*} [state=initialState]
 * @param {{ payload: any, type: string }} action
 * @returns
 */
export default function errorPass(state = initialState, action: { payload: any, type: string }) {

  switch (action.type) {
    case ERROR_FOR_SHOW:
      return { ...state, foundError: action.payload };
    case NO_DATA:
      return { ...state, noData: action.payload };
    default:
      return state;
  }
  return state;
}
