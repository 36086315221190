import { ERROR_FOR_SHOW, NO_DATA } from './Types';

/**
 *
 *
 * @export
 * @param {{ message: string }} options
 * @returns {{ payload: { message: string }, type: string }}
 */
export function foundError(options: { message: string }): { payload: { message: string }, type: string } {
  return {
    type: ERROR_FOR_SHOW,
    payload: options,
  };
}

/**
 *
 *
 * @export
 * @param {boolean} status
 * @returns {{ payload: boolean, type: string }}
 */
export function noData(status: boolean): { payload: boolean, type: string } {
  return {
    type: NO_DATA,
    payload: status,
  };
}
