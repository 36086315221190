import Layout from '../components/Layout/Layout';
import ReportTrend from '../components/Report/ReportTrend';
// import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';

export const countries = ['thailand'];
export const sortType = ['recent', 'trend'];
export const categories = [
  '',
  'advertising',
  'auto',
  'automobile',
  'beauty',
  'bank-financial-institution',
  'brands',
  'digital-tv',
  'drink-alc',
  'e-commerce',
  'education',
  'entertainment',
  'financial',
  'food',
  'government',
  'health-sport',
  'healths',
  'hospital',
  'inspiration',
  'media',
  'news',
  'others',
  'people-blogger',
  'politics',
  'promotion',
  'real-estate',
  'restrict',
  'shops-retails',
  'sport',
  'technology',
  'telecommunication',
  'travel',
  'variety',
  'website',
  'youtuber',
  'mkt',
  'mom-kids',
  'movie',
  'youtuberxinfluencer',
  'laos',
];

// const indexRoutes = [
//   {
//     path: '/',
//     name: 'ALL', component: Layout,
//   },
//   { path: '/thailand/trend/auto', name: 'Thailand', component: Layout },
//   // { path: '/thailand/trend', component: Layout, exact: false },

// ];
const indexRoutes = () => {
  const routes: any[] = [];
  countries.forEach((country: string) => {
    sortType.forEach((sort: string) => {
      categories.forEach((category: string) => {
        routes.push({
          path: `/${country}/${sort}/${category}`,
          component: Layout,
          exact: true,
        });
      });
    });
  });
  routes.push({ path: `/`, component: Layout, exact: true });
  // routes.push({ path: `/report`, component: ReportTrend, exact: true });
  // routes.push({ path: `/privacy-policy`, component: PrivacyPolicy, exact: true });

  return routes;
};

export default indexRoutes;
