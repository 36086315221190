import { SELECT_SORT_TYPE } from '../actions/Types';

const initialState = {
    sort_type: 'trend',
};

/**
 *
 *
 * @export
 * @param {*} [state=initialState]
 * @param {{ payload: any, type: string }} action
 * @returns
 */
export default function selectSortType(state = initialState, action: { payload: any, type: string }) {
    switch (action.type) {
        case SELECT_SORT_TYPE:
            return { ...state, sort_type: action.payload };
        default:
            return state;
    }
    return state;
}
