import React, { useEffect, useState } from 'react';
import WordCloudChart from '../Echarts/WordCloudChart';
import NoData from '../NoData';
import { ITopHashTag } from '../ReportTrend';

const TopHashTagChart = (props: { data: ITopHashTag[] }) => {
  const { data } = props;
  const [isNoData, setIsNoData] = useState(false);

  useEffect(() => {
    if (!data || data.length === 0) {
      setIsNoData(true);
    } else {
      setIsNoData(false);
    }
  }, [data]);

  const option = getOption(data);
  return (
    <section>
      <div className='title'>
        <h3>Top hashtag</h3>
      </div>
      <div className='chart-container' style={{ height: '280px' }}>
        {isNoData ? <NoData/> : <WordCloudChart chartOption={option} />}
      </div>
    </section>
  );
};

export default TopHashTagChart;

const getOption = (data: ITopHashTag[] = []) => {
  return {
    data: data.map((d: ITopHashTag) => {
      return {
        name: `#${d.name}`,
        value: parseInt(d.count, 10),
      };
    }),
  };
};
