import { SELECT_SORT_TYPE } from './Types';

/**
 *
 *
 * @export
 * @param {string} type
 * @returns {{ payload: string, type: string }}
 */
export function selectSortType(type: string): { payload: string, type: string } {
    return {
        type: SELECT_SORT_TYPE,
        payload: type,
    };
}
