import React from 'react';
import { converToNumberFormat } from '../../../sheard/functions/numeral';
import EchartComponent from './Echart';

const PieChart = (props: { chartOption: IPieChartOption }) => {
  const chartOption = props.chartOption;

  const option = getOption(chartOption);
  return <EchartComponent options={option} />;
};

export default PieChart;

interface IPieChartOption {
  pie_data: any;
  data_color: string[];
  min: number;
  max: number;
}

const getOption = (params: IPieChartOption) => {
  return {
    grid: {
      height: '250px',
    },
    tooltip: {
      formatter: '{a} <br/>{b} : {c}%',
    },
    series: [
      {
        name: 'Pressure',
        type: 'gauge',
        min: params.min,
        max: params.max,
        progress: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
        detail: {
          valueAnimation: false,
          formatter: (value: any) => {
            return converToNumberFormat(value);
          },
          fontSize: 15,
          offsetCenter: ['0', '50%'],
        },
        title: {
          offsetCenter: ['0', '25%'],
          fontSize: 11,
        },
        data: params.pie_data,
        color: params.data_color,
      },
    ],
  };
};
