import React from 'react';
import { colorCode } from '../../../sheard/functions/color-code';
import EchartComponent from './Echart';

const BarLineChart = (props: { chartOption: IChartOption }) => {
  const chartOption = props.chartOption;
  const formatTooltip = chartOption.format_tooltip;
  const dataColor = chartOption.data_color;
  const xAxisData = chartOption.xAxis_data;
  const data = chartOption.data;

  const option = getOption(formatTooltip, dataColor, xAxisData, data);
  return <EchartComponent options={option} />;
};

export default BarLineChart;

interface IChartOption {
  format_tooltip: any;
  xAxis_data: any;
  data: any;
  data_color: any;
}

const scaleAxisLabel = (axisLabelCount: number) => {
  if (axisLabelCount < 13) {
    return 0;
  } else if (axisLabelCount < 25) {
    return 1;
  } else  if (axisLabelCount < 45) {
    return 2;
  } else {
    return 3;
  }
};

const getOption = (
  formatTooltip: any,
  dataColor: any,
  xAxisData: any,
  data: any,
) => {
  return {
    tooltip: {
      trigger: 'axis',
      transitionDuration: 0.2,
      padding: 12,
      backgroundColor: colorCode.black,
      extraCssText: 'box-shadow: 0 0 10px 0 #C2C4C5; border-radius: 4px;',
      formatter: formatTooltip,
      textStyle: {
        color: colorCode.white,
        fontSize: 11,
      },
    },
    legend: {
      top: 0,
      right: 0,
      icon: 'circle',
      itemHeight: 10,
      textStyle: {
        color: colorCode.font_primary,
        fontFamily: 'sans-serif',
        fontSize: 12,
      },
    },
    // height: 200,
    animation: false,
    color: dataColor,
    grid: {
      x: 60,
      y: 40,
      x2: 16,
      y2: 32,
      borderColor: colorCode.transparent,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
        splitLine: {
          lineStyle: {
            color: colorCode.transparent,
          },
        },
        axisLine: {
          lineStyle: {
            color: colorCode.transparent,
          },
        },
        axisTick: {
          lineStyle: {
            color: colorCode.transparent,
          },
        },
        axisLabel: {
          margin: 12,
          textStyle: {
            color: colorCode.text_low,
            fontSize: 11,
          },
          interval: scaleAxisLabel(xAxisData.length),
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        minInterval: 1,
        splitLine: {
          lineStyle: {
            color: colorCode.light_border,
          },
        },
        axisLine: {
          lineStyle: {
            color: colorCode.transparent,
          },
        },
        axisTick: {
          lineStyle: {
            color: colorCode.transparent,
          },
        },
        axisLabel: {
          margin: 8,
          textStyle: {
            color: colorCode.text_low,
            fontSize: 11,
          },
          formatter: '{value}',
        },
      },
    ],
    itemStyle: {
      borderRadius: 6,
    },
    series: data,
  };
};
