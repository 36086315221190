import { combineReducers } from 'redux';
import CategoriesReducer from './Categories';
import DialogReducer from './dialog';
import ErrorReducer from './error';
import LoadingReducer from './Loading';
import selectSortType from './SortStream';
import StreamsReducer from './Stream';

export default combineReducers({
    dialog: DialogReducer,
    category: CategoriesReducer,
    stream: StreamsReducer,
    sort_stream: selectSortType,
    loading: LoadingReducer,
    error: ErrorReducer,
});
