// const ENV: any = process.env.REACT_APP_ENV_API;
import axios from 'axios';

// import * as development from './../../environments/environment.development';
// import * as production from './../../environments/environment.production';
// import * as staging from './../../environments/environment.staging';
// tslint:disable-next-line:no-var-requires
const dotenv = require('dotenv').config();

interface IParam {
    path: string;
    data?: any;
}

let CONFIG: { api_url: string };
export class Api {
    private configuration: { api_url: string } = { api_url: 'http://localhost:8088' };

    constructor() {
        // tslint:disable-next-line:no-console
        console.log(process.env.REACT_APP_STAGE);
        switch (process.env.REACT_APP_STAGE) {
            case 'production':
                this.setConfig = { api_url: 'https://trends-middleware.socialenable.co' };
                break;
            case 'staging':
                this.setConfig = { api_url: 'https://middleware-staging.social.gg' };
                break;
            default:
                this.setConfig = { api_url: 'http://localhost:8088' };
                break;
        }
    }

    /**
     *
     *
     * @static
     * @param {IParam} param
     * @returns {{ url: string }}
     * @memberof Api
     */
    public static urlPath(param: IParam): { url: string } {
        const option: any = {
            url: `${CONFIG.api_url}${param.path}`,
        };
        return option;
    }

    /**
     *
     *
     * @static
     * @param {IParam} param
     * @returns {*}
     * @memberof Api
     */
    public static get(param: IParam): any {
        // tslint:disable-next-line:no-unused-expression
        const option = this.urlPath(param);
        return axios.get(option.url);
    }

    /**
     *
     *
     * @static
     * @param {IParam} param
     * @returns {*}
     * @memberof Api
     */
    public static post(param: IParam): any {
        const option = this.urlPath(param);
        return axios.post(option.url, param.data);
    }

    /**
     *
     *
     * @static
     * @param {IParam} param
     * @returns {*}
     * @memberof Api
     */
    public static put(param: IParam): any {
        const option = this.urlPath(param);
        return axios.post(option.url, param.data);
    }

    /**
     *
     *
     * @static
     * @param {IParam} param
     * @returns {*}
     * @memberof Api
     */
    public static delete(param: IParam): any {
        const option = this.urlPath(param);
        return axios.delete(option.url, param.data);
    }

    /**
     *
     *
     * @memberof Api
     */
    // tslint:disable-next-line:no-shadowed-variable
    public set setConfig(config: { api_url: string }) {
        this.configuration = {
            api_url: config.api_url,
        };
        CONFIG = this.configuration;
    }

    /**
     *
     *
     * @readonly
     * @type {*}
     * @memberof Api
     */
    public get getConfig(): { api_url: string } {
        return this.configuration;
    }
}
