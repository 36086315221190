import React, { CSSProperties } from 'react';
import { PuffLoader } from 'react-spinners';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
};

const OverlayLoading = () => {
  return (
    <div className='overlay-loading'>
    <PuffLoader color='gray' cssOverride={override} size={120} />
  </div>

  );
};

export default OverlayLoading;
