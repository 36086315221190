import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getWeeklyHeatmap } from '../../../sheard/services/ReportService';
import ScatterChart from '../Echarts/ScatterChart';
import { LoadingContainer } from '../Loading';
import { IBubbleChart } from '../ReportTrend';

const BubbleChart = (props: { data: IBubbleChart[], query: any }) => {
  const [type, setType] = useState<string>('days');
  const [option, setOption] = useState<any>(props.data);
  const [chartData, setChartData] = useState<any>(null);
  const [chartDataWeek, setChartDataWeek] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (type === 'days') {
      setChartData(props.data);
      return;
    }
    if (chartDataWeek) {
      setChartData(chartDataWeek);
      return;
    }
    setIsLoading(true);
    getWeeklyHeatmap(props.query)
      .then((res: any) => {
        setChartDataWeek(res.data);
        setChartData(res.data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
      });
  }, [type]);

  useEffect(() => {
    setChartData(props.data);
  }, []);

  useEffect(() => {
    let _option;
    if (type === 'days') {
      _option = getOptions(chartData || []);
    } else {
      _option = getWeeklyOptions(chartData || []);
    }
    setOption(_option);
  }, [chartData]);

  return (
    <section>
      <div className='title'>
        <div className='filter-box'>
          <h3>Heat Map</h3>
          <div className='filter-option'>
            <div
              onClick={() => {
                setType('days');
              }}
              className={
                type === 'days' ? 'active option-button' : 'option-button'
              }
            >
              Days & Time
            </div>
            <div
              onClick={() => {
                setType('week');
              }}
              className={
                type === 'week' ? 'active option-button' : 'option-button'
              }
            >
              Week & Time
            </div>
          </div>
        </div>
      </div>
      <div className='chart-container' style={{ height: '280px' }}>
        {isLoading ? (
          <LoadingContainer />
        ) : (
          <ScatterChart chartOption={option} />
        )}
      </div>
    </section>
  );
};

const getOptions = (data: IBubbleChart[] = []) => {
  const datas: any = [];
  const yAxisData: string[] = [];
  const mockHours: any = new Array(24);
  data.forEach((d: IBubbleChart, dayIndex: number) => {
    const m = moment(d.date_by_day);
    yAxisData.push(m.format('DD MMM'));
    mockHours.fill(true).forEach((v: boolean, hour: number) => {
      const hourKey = hour < 10 ? `0${hour}` : `${hour}`;
      const dateByHour = _.find(d.date_by_hour, ['hour', hourKey]);
      let value = 0;
      if (dateByHour) {
       value = parseInt(dateByHour.count, 10);
      }
      if (value > 0) {
        datas.push([hour, dayIndex, value]);
      }
    });
  });
  return {
    data: datas,
    yAxisData,
  };
};

const getWeeklyOptions = (data: any) => {
  const datas: any = [];
  const dayOfWeekKeys = [
    'Saturday',
    'Friday',
    'Thursday',
    'Wednesday',
    'Tuesday',
    'Monday',
    'Sunday',
  ];
  const mockHours: any = new Array(24);
  dayOfWeekKeys.forEach((dayOfWeekKey: string, dayOfWeekIndex: number) => {
    mockHours.fill(true).forEach((v: boolean, hour: number) => {
      const value = data[dayOfWeekKey][hour] || 0;
      if (value > 0) {
        datas.push([hour, dayOfWeekIndex, value]);
      }
    });
  });
  return {
    data: datas,
    yAxisData: dayOfWeekKeys,
  };
};

export default BubbleChart;
