import Axios, { AxiosError } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import MockAdapter from 'axios-mock-adapter';
import mockReportAPI from './mock-api/report';

const isMockAPI = process.env.REACT_APP_MOCK_API === '1';
const url = 'https://trends-middleware.socialenable.co';

const cache = setupCache({
  maxAge: 5 * 60 * 1000,
  exclude: {query: false},
});

const baseUrl = process.env.REACT_APP_APP_URL || url;
const newAPIClient = Axios.create({
  baseURL: `${baseUrl}/api`,
  // timeout: 1000,
  adapter:  cache.adapter,
});

newAPIClient.interceptors.request.use((config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = localStorage.getItem('token');
    }
    return config;
});

newAPIClient.interceptors.response.use((response: any) => response , (error: AxiosError): Promise<any> => {
  let res = {};
  if (error) {
    const response: any = error.response || {};
    res = {
      status: response.status,
      message: response.data ? response.data.message : 'An unexpected error occurred',
    };
  }
  return Promise.reject(res);
});

if (isMockAPI) {
  const mockAdapter = new MockAdapter(newAPIClient, { delayResponse: 1000 });
  mockReportAPI(mockAdapter);
}

export default newAPIClient;
