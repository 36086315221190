export const OPEN_DIALOG_CATEGORY = 'OPEN_DIALOG_CATEGORY';
export const CLOSE_DIALOG_CATEGORY = 'CLOSE_DIALOG_CATEGORY';
export const SAVE_CATEGORIES = 'SAVE_CATEGORIES';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SAVE_STREAM = 'SAVE_STREAM';
export const SELECT_SORT_TYPE = 'SELECT_SORT_TYPE';
export const LOADING_INIT = 'LOADING_INIT';
export const LOADING_MORE = 'LOADING_MORE';
export const ERROR_FOR_SHOW = 'ERROR_FOR_SHOW';
export const NO_DATA = 'NO_DATA';
