import * as _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { foundError } from './actions/error';
import Category from './components/Category/Category';
import Header from './components/Hearder/Header';
import ReportTrend from './components/Report/ReportTrend';
import history from './history';
import indexRoutes from './routes/index';

interface IState {
  error: { foundError: { message: string } };
}

class AppRouter extends React.Component<any, any> {
  public genRoutes() {
    const routes = indexRoutes();
    return routes.map((prop, key) => {
      return (
        <Route
          preserveScrollPosition={true}
          path={prop.path}
          component={prop.component}
          key={key}
          exact={prop.exact}
        />
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className='trend'>
          <Header />
          {/* <Category /> */}
          <Router history={history}>
            <Switch>
              <Redirect exact from='/' to='/thailand/trend/' />
              {this.genRoutes()}
              <Route
                render={(props) => canAccessReport() ? <ReportTrend /> : <Redirect to='/' />}
              />
              <Redirect exact from='*' to='/thailand/trend/' />
            </Switch>
          </Router>
        </div>
        {this.props && this.props.foundError ? (
          <div className='alert fail show'>
            <p>
              <i className='fas fa-times-circle' />
              {this.props.foundError}
            </p>
            <i
              className='fas fa-times close'
              onClick={() => {
                this.props.sendFoundError(undefined);
              }}
            />
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return {
    foundError:
      state.error && state.error.foundError && state.error.foundError.message
        ? state.error.foundError.message
        : '',
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      sendFoundError: foundError,
    },
    dispatch,
  );
};

const canAccessReport = () => {
  if (localStorage.getItem('token')) {
    return true;
  }
  return false;
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
