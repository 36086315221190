export const colorCode = {
  transparent: 'rgba(255,255,255,0)',
  light_border: '#E4E8EF',
  text_low: '#757575',
  white: 'rgba(255,255,255,0.8)',
  black: '#35303B',

  font_primary: '#37474F',
  font_secondary: '#757575',

  red: '#E57373',
  red_2: 'rgba(229,115,115,0.25)',

  orange: '#FFB74D',
  orange_2: 'rgba(255,183,77,0.25)',

  yellow: '#FFD54F',
  yellow_2: 'rgba(255,213,79,0.25)',

  green: '#81C784',
  green_2: 'rgba(129,199,132,0.25)',
  light_green: '#AED581',

  light_blue: '#4FC3F7',
  light_blue_2: 'rgba(79,195,247,0.25)',
  light_blue_3: '#BBDEFB',

  dark_blue: '#0288D1',
  dark_blue_2: '#0D47A1',

  indigo: '#7986CB',

  blue: '#64B5F6',
  blue_2: 'rgba(100,181,246,0.25)',

  purple: '#9575CD',
  purple_2: 'rgba(149,117,205,0.25)',

  pink: '#F06292',
  pink_2: 'rgba(255,128,171,0.25)',

  cyan: '#4DD0E1',
  cyan_2: 'rgba(77,208,255,0.25)',

  gray: '#BDBDBD',
  light_gray: '#EEEEEE',
  gray_2: 'rgba(189,189,189,0.25)',

  crimson: '#93291E',
  deep_purple: '#C33764',

  // Sentiment
  neutral: '#BDBDBD',
  positive: '#11B873',
  negative: '#ED5D65',

  // Social
  facebook: '#3B5998',
  facebook_2: 'rgba(59, 89, 152, 0.5)',
  twitter: '#00ACED',
  twitter_2: 'rgba(0, 172, 237, 0.5)',
  instagram: '#517FA4',
  instagram_2: 'rgba(81, 127, 164, 0.5)',
  youtube: '#FF0000',
  blog: '#009688',
  webboard: '#FB8C00',
  line: '#00c402',
  lithium: '#0075c4',
  mojito: '#7bb342',
  playstore: '#425761',
  shopee: '#EE4D2D',
  tiktok: '#000000',
  website: '#0e9386',

  // Status CS
  new: '#0097A7',
  open: '#FFBE21',
  pending: '#2196F3',
  on_hold: '#FB8C00',
  solve: '#388E3C',
  close: '#BDBDBD',
  remove: '#F44336',

  // Rating
  fiveStar: '#81C784',
  fourStar: '#AED581',
  threeStar: '#FFD54F',
  twoStar: '#FFB74D',
  oneStar: '#E57373',
  average: '#64B5F6',
  trend: '#F06292',
};
