import moment from 'moment';

export const convertDateTypeToTimeRanges = (type: string) => {
    const now = moment();
    let startDate = moment();
    let endDate = moment();

    switch (type) {
        case 'yd':
          startDate = moment().subtract(1, 'day').startOf('day');
          endDate = moment().subtract(1, 'day').endOf('day');
          break;
        case 'l7':
          startDate = moment().subtract(7, 'days').startOf('day');
          endDate = moment().subtract(1, 'days').endOf('day');
          break;
        case 'l14':
          startDate = moment().subtract(14, 'days').startOf('day');
          endDate = moment().subtract(1, 'days').endOf('day');
          break;
        case 'tm':
          startDate = moment().startOf('month');
          endDate = now.endOf('day');
          break;
        case 'lm':
          startDate = moment().subtract(1, 'month').startOf('month');
          endDate = moment().subtract(1, 'month').endOf('month');
          break;
        case 'mr':
          startDate = moment().subtract(45, 'days').startOf('day');
          endDate = now.endOf('day');
          break;
        default:
          startDate = endDate = moment();
          break;
      }

    return {start_date: startDate.utc().unix(),
        end_date: endDate.utc().unix()};

};
