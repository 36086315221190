import React, { useEffect, useState } from 'react';
import NightingalChart from '../Echarts/NightingalChart';
import NoData from '../NoData';
import { IShareOfVoice } from '../ReportTrend';

const ShareOfVoiceChart = (props: { data: IShareOfVoice[] }) => {
  const { data } = props;
  const [isNoData, setIsNoData] = useState(false);

  useEffect(() => {
    if (!data || data.length === 0) {
      setIsNoData(true);
    } else {
      setIsNoData(false);
    }
  }, [data]);

  const option = getOption(data);
  return (
    <section>
      <div className='title'>
        <h3>Share of voices</h3>
      </div>
      <div
        className='chart-container'
        style={{ height: '420px', width: '100%' }}
      >
        {isNoData ? <NoData /> : <NightingalChart chartOption={option} />}
      </div>
    </section>
  );
};

export default ShareOfVoiceChart;

const getOption = (data: IShareOfVoice[] = []) => {
  const values = data.map((d: IShareOfVoice) => {
    return {
      value: parseInt(d.count, 10),
      name: d.name,
    };
  });
  return { data: values };
};
