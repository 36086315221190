import React, { Component } from "react";
import { connect } from "react-redux";
import { getStreamsWithNPUrl } from "../../sheard/services/StreamService";
import Masonry from "react-masonry-component";
import _ from "lodash";
import moment from "moment";
import { loadingMore } from "../../actions/Loading";
import { bindActionCreators } from "redux";
import { foundError } from "../../actions/error";
import { noData } from "../../actions/error";

const masonryOptions = {
  transitionDuration: 0,
};

const imagesLoadedOptions = { background: ".my-bg-image-el" };

let page = 1;
let previousInBottom;
let nextPageUrl = false;

class OrdinaryMasonry extends Component {
  constructor(props, context) {
    super(props, context);
    page = 1;
    this.loadStream();
    this.handleScroll = this.handleScroll.bind(this);
    this.props.loadingMore(true);
    this.props.sendNoData(false);
  }

  render() {
    let lists = [];
    if (this.state && this.state.streams && this.state.streams.length) {
      this.state.streams.forEach((stream, i) => {
        lists.push(this.realitem(stream, i));
      });
    }
    return this.masonry(lists);
  }

  realitem(stream, i) {
    // console.log(stream.created_time)

    return (
      <div className="item animation" key={i}>
        <a className="feed" href={stream.permalink} target="_blank">
          <div className="image">
            <img src={stream.image} alt="" />
          </div>
          <div className="detail">
            <header>
              <span className="avatar">
                <img src={stream.avatar_url} alt="" />
              </span>

              <p className="name">{stream.owner_ref_name} </p>
              <p className="date-time">
                {stream.display_timeago
                  ? moment(stream.created_time).fromNow()
                  : moment(stream.created_time * 1000).format(
                      "DD/MM/YYYY [at] hh:mm a"
                    )}

                {/*                 
                  // ? 'AAA'
                  // : moment(stream.created_time).moment().format('L')}
                  {/* //  : stream.display_created_time}  */}
              </p>
            </header>
            <div className="message">
              <p>{stream.title}</p>
            </div>
            <ul className="summary">
              <li>
                <p>
                  <i className="fas fa-thumbs-up" />
                  {stream.likes_count}
                </p>
              </li>
              <li>
                <p>
                  <i className="fas fa-comment" />
                  {stream.comment_count}
                </p>
              </li>
              <li>
                <p>
                  <i className="fas fa-share" />
                  {stream.share_count}
                </p>
              </li>
            </ul>
          </div>
        </a>
      </div>
    );
  }

  masonry(childElements) {
    return (
      <Masonry
        className={"my-gallery-class"} // default ''
        elementType={"ul"} // default 'div'
        options={masonryOptions} // default {}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        imagesLoadedOptions={imagesLoadedOptions} // default {}
      >
        {childElements}
      </Masonry>
    );
  }

  handleScroll = () => {
    const listScroll = window.document.getElementsByClassName("list-scroll")[0];
    if (
      listScroll.scrollTop + listScroll.offsetHeight >=
      (listScroll.scrollHeight * 90) / 100
    ) {
      if (!previousInBottom) {
        this.loadStream();
        previousInBottom = true;
      }
    }
  };

  loadStream() {
    getStreamsWithNPUrl(
      this.props.categorySelected.permalink,
      this.props.sort_type,
      page
    )
      .then((data) => {
        nextPageUrl = !!data.next_page_url;
        if (!nextPageUrl) {
          this.props.loadingMore(false);
        }
        const streams = [];
        for (const key in data.items) {
          if (data.items[key]) {
            streams.push(data.items[key]);
          }
        }
        previousInBottom = false;
        if (streams) {
          const mappedStreams = streams.map((stream) => {
            return {
              created_time: stream.created_time,
              title: stream.message ? stream.message.substring(0, 100) : "",
              image: stream.background_image,
              avatar_url: stream.avatar_url,
              owner_ref_name: stream.owner_ref_name,
              permalink: stream.permalink,
              likes_count: stream.likes_count,
              share_count: stream.share_count,
              comment_count: stream.comment_count,
              format_datetime: stream.format_datetime,
              display_timeago: stream.display_timeage,
              display_full_datetime: stream.display_full_datetime,
              display_created_time: stream.display_created_time,
            };
          });
          if (mappedStreams.length > 0) {
            if (page > 1 && this.state && this.state.streams) {
              this.setState({
                streams: [...this.state.streams, ...mappedStreams.slice(0)],
              });
            } else {
              this.setState({ streams: [..._.clone(mappedStreams)] });
            }
            page++;
          }
          if (mappedStreams.length === 0) {
            this.props.loadingMore(false);
            if (page === 1) {
              this.props.sendNoData(true);
            }
          }
        }
      })
      .catch((err) => {
        previousInBottom = false;
        this.props.foundError(err);
      });
  }

  componentDidMount() {
    window.document
      .getElementsByClassName("list-scroll")[0]
      .addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.document
      .getElementsByClassName("list-scroll")[0]
      .removeEventListener("scroll", this.handleScroll);
  }
}

const mapStateToProps = (state) => {
  return {
    categorySelected: state.category.categorySelected,
    streams: state.stream.stream,
    sort_type: state.sort_stream.sort_type,
    loadingInit: state.loading.loadingInit,
    categories: state.category.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadingMore: loadingMore,
      foundError: foundError,
      sendNoData: noData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdinaryMasonry);
