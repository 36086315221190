import { CLOSE_DIALOG_CATEGORY, OPEN_DIALOG_CATEGORY } from './Types';

/**
 *
 *
 * @export
 * @returns {{ payload: string, type: string }}
 */
export function openDialogCategory(): { payload: string, type: string } {
    return {
        type: OPEN_DIALOG_CATEGORY,
        payload: 'category',
    };
}

/**
 *
 *
 * @export
 * @returns {{ payload: string, type: string }}
 */
export function closeDialogCategory(): { payload: string, type: string } {
    return {
        type: CLOSE_DIALOG_CATEGORY,
        payload: '',
    };
}
