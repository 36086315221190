import React, { Component, Fragment } from 'react';
import Header from '../Hearder/Header';

class PrivacyPolicy extends Component {

    render() {
        return (
            <Fragment>
              <div className="privacy-policy">
                <Header />
              <main>
                <h2>Privacy policy</h2>
                <article>
                  <h3>PRIVACY</h3>
                  <p>Computerlogy Company Limited, having the registration number of 0205552022384 and having the registered office at 64/3 Mu 1, Soi Wat Wang Hin, Surasak Sub-district, Si Racha, Chonburi 20110, Thailand (the “Company“) is the owner and the developer of Trends.socialenable, the social media management solution (the “Trends.socialenable”). By signing up and registering to be the user to use the service of Trends.socialenable (“User”), the User acknowledges and understands that the Company would need to process the personally identifiable information (the “PII“) of such particular User. Thus, the Company announces this Privacy Policy to notify the User of the rights and obligations as well as the conditions for the collection, use, process and disclosure of the User’s PII.</p>
                  <p className='no-indent'>This Privacy Policy describes how the User’s information is collected, used and shared when the User is using Trends.socialenable.</p>
                  <p>This Privacy Policy is only applicable to the use of Trends.socialenable and shall not apply to the PII process undertaken by other application or website developed and operated by any other third-party that the Company does not have any control over (the “Third Party“) that being displayed or connected to Trends.socialenable. The User understands and agrees that the User shall study and agree on the privacy policy announced by those Third Party that is separated from this Privacy Policy.</p>
                  <p>If the User does not accept this Privacy Policy and any amendment thereof, the Company reserves the rights to refrain from providing any services to those Users since the processing of the PII defined hereunder are critical for the provisions of Trends.socialenable services by the Company to the User. By continuing to use Trends.socialenable, the relevant User shall be deemed to always accept and agree to the Privacy Policy.</p>
                  <p>The Company may amend or revise this Privacy Policy at any time to assure the compliance with the relevant laws and regulations and to assure the updates in Trends.socialenable at any time; provided that the Company will communicate the amendments or revisions made on Trends.socialenable and the amendment or revisions shall become effective once communicating on Trends.socialenable.</p>
                  <h3>What kinds of PII is being processed? </h3>
                  <p>In order to perform the required services and features defined on Trends.socialenable, the Company would need to collect and process the following PII from the User:</p>
                  <ul>
                    <li>Directly Identifiable PII, including without limitation name, age, nationality and date of birth</li>
                    <li>Contact information, including without limitation, address, phone number, and e-mail address</li>
                    <li>User’s online identifier, including username and password</li>
                    <li>Trends.socialenable transactional data, including without limitation username, password, transaction number and history that the User transact on Trends.socialenable, including the gathered preference and interest from Trends.socialenable transactional behaviour</li>
                    <li>Technical PII, including without limitation the IP address number, Trends.socialenable usage, setting, and web browser connection that has been connected to Trends.socialenable</li>
                    <li>Content, communications and other information the User provides using Trends.socialenable, including when the User signs up for an account, create or share content, and message or communicate with others. This can include information in or about the content the User provides (such as metadata), such as the location of a photo or the date a file was created</li>
                    <li>Content, communications and information that other people provide when they use Trends.socialenable. This can include information about the User, such as when they share or comment on a photo or the content of the User, send a message to the User, or upload, sync or import the User’s contact information and</li>
                    <li>All communications with other users of Trends.socialenable and with the Company</li>
                  </ul>
                  <h3>How Trends.socialenable is processing the User’s PII?</h3>
                  <ul>
                    <li>The Company would need to collect, restore and use the PII of the User in order to perform any services or function the specific features of Trends.socialenable as defined under the Terms of Service, including without limitation (i) to verify the identity and eligibility of the User; (ii) to monitor the transaction undertaken on Trends.socialenable executed by the User; (iii) to contact the relevant User; and (iv) to display the relevant PII on Trends.socialenable interface in accordance with the scope of services provided under the Terms of User / Terms of Services</li>
                    <li>The Company would need to collect and analyze the PII of the User in order to assess the User’s interest with an aim to provide customized and personalized privileges or service that would meet your interest and preference and to improve our customer’s experience with each relevant User</li>
                    <li>The Company would need to collect and restore the User’s PII in order to assure the appropriate after-sale service provided by the Company in various forms, including the satisfaction survey or the support and complaint redress function</li>
                    <li>The User would need to collect and restore the User’s PII as obliged under the applicable laws and regulations, for instance, for the withholding tax payment purpose.</li>
                  </ul>
                  <p>The Company represent that the Trends.socialenable does not adopt the automated function of PII processing regarding individual decision making, including profiling.</p>
                  <p>The Company would need to collect and store the PII of each relevant User for the defined purposes for as long as the User is still the registered User on Trends.socialenable, except the relevant laws and regulations defined otherwise. The storage of the PII after the User termination would be necessary for the legitimate interest of the Company in the legal proceedings that the Company may have against the relevant User.</p>
                  <h3>Disclosure of the PII</h3>
                  <p>In order to assure the performance of Trends.socialenable as committed, the Company may need to disclose the User’s PII in the following circumstances</p>
                  <ul>
                    <li>To disclose the User’s PII to the outsourced service providers engaged in the performing direct service to Trends.socialenable, including without limitation the advisors and auditors as well as the logistic service provider; provided that the Company shall only disclose the User’s PII to the relevant recipient strictly on the need to know basis in strict compliance with the defined objectives for PII process defined</li>
                    <li>To disclose the User’s PII in the usage of the storage service on cloud, including Google Cloud Platform</li>
                    <li>To disclose the User’ PII to the third party in the legal proceedings to protect the Company’s legitimate rights or to detect and prevent any fraud on Trends.socialenable; provided that such disclosure shall be done on the limited and specific purposes as defined</li>
                    <li>In case that the Company is obliged under the applicable laws, court judgment or administrative order to disclose any PII of any particular users, the Company would need to do so only on the necessary basis.</li>
                  </ul>
                  <h3>Representation on the Privacy Security</h3>
                  <p>The Company represents and guarantees that the Company shall use the most appropriate security measures to prevent the unauthorized access, amendment or disclosure of the PII in any form or in any circumstance by either internal or external persons, including without limitation encryption and system testing; and the Company commits to review those measures on the regular basis with the strong commitment to use the best industrial practice and to be in strict compliance with the applicable laws.</p>
                  <h3>Data Subject Rights</h3>
                  <p>The Company acknowledges and accepts the User’s rights as the data subject over their PII as defined under the applicable laws that include the following rights:</p>
                  <ul>
                    <li>Right to withdraw consent: Users can withdraw their consent and request the processors to stop collecting their personal data</li>
                    <li>Right of Access: Users can submit data access requests, which oblige processors to provide a copy of any personal data they hold regarding data subjects. This includes a request for a disclosure of platforms and methods in which the processors collected the data from</li>
                    <li>Right of Rectification: Users can request an update on inaccurate or incomplete personal data</li>
                    <li>Right to Erasure: Users can request that the service provider erase their data in certain circumstances, such as when the data is no longer necessary, the data was unlawfully processed or is no longer meeting the lawful ground for which it was collected. This includes the instance where the individual withdraws consent</li>
                    <li>Right to Restriction of Processing: Users can request the service provider to limit the way their personal data is used.</li>
                    <li>Right to Data Portability: Users are permitted to obtain and reuse their personal data for their own purposes across different services.</li>
                    <li>Right to be informed: Users have the right to be notified about the collection of their personal data such as storage periods and purposes.</li>
                    <li>Right to Object: Users can object to the processing of personal data that is collected on the grounds of legitimate interests or the performance of a task in the interest/exercise of official authority. </li>
                    <li>Right not to be subject to automated individual decision-making: significant decision will not be solely based on automated means</li>
                  </ul>
                  <p>The User can contact the Company in order to make the request to exercise any defined rights through the defined channel without any charge and the Company will consider and notify the User of the Company’s determination within 30 days after the receipt of the User’s valid request.</p>
                </article>
                <article>
                  <h3>PRIVACY ANOTHER</h3>
                  <p className='no-indent'><span className='hilight'>YouTube Terms of Service : </span>This summary is designed to help you understand some of the key updates we’ve made to our Terms of Service (Terms). We hope this serves as a useful guide, but please ensure you read the new Terms in full.</p>
                  <p className='no-indent'><span className='hilight'>Google Privacy Policy : </span>This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information.</p>
                  <p className='no-indent'><span className='hilight'>Google security : </span>As an administrator for your organization’s G Suite or Cloud Identity service, you can view and manage security settings for a user. For example, you can reset a user’s password, add or remove security keys for multi-factor authentication, and reset user sign-in cookies.</p>
                </article>
                <article>
                  <h3>Contact Us</h3>
                  <h4>Data Controller</h4>
                  <ul className='no-point'>
                    <li>Computerlogy Company Limited</li>
                    <li>64/3 Mu 1, Soi Wat Wang Hin, Surasak Sub-district, Si Racha, Chonburi 20110, Thailand </li>
                    <li>038-321-100</li>
                    <li>info@computerlogy.com</li>
                    <li>www.computerlogy.com</li>
                    <li>https://www.facebook.com/computerlogy.co.ltd/</li>
                    <li>https://www.instagram.com/computer.logy/</li>
                  </ul>
                  <h4>Data Protection Officer</h4>
                  <ul className='no-point'>
                    <li>Mr. Komson Palawat</li>
                    <li>64/3 Mu 1, Soi Wat Wang Hin, Surasak Sub-district, Si Racha, Chonburi 20110, Thailand</li>
                    <li>038-321-100</li>
                    <li>info@computerlogy.com</li>
                  </ul>
                </article>
              </main>
              </div>
            </Fragment>
        );
    }
}

export default PrivacyPolicy;
