import _ from 'lodash';
import React from 'react';
import { converToNumberFormat } from '../../../sheard/functions/numeral';
import EchartComponent from './Echart';

const ScatterChart = (props: { chartOption: IScatterChartOption }) => {
  const chartOption = props.chartOption;

  const option = getOption(chartOption);
  return <EchartComponent options={option} />;
};

export default ScatterChart;

interface IScatterChartOption {
  data: any;
  yAxisData: string[];
}

const getOption = (params: IScatterChartOption) => {
  const { data, yAxisData } = params;
  let min = 0;
  let max = 0;

  if (data) {
    const values: any = data.filter((d: any) => d[2]).map((d: any) => d[2]);
    min = _.min(values) || 0;
    max = _.max(values) || 0;
  }
  const hours = [
    '12am',
    '1am',
    '2am',
    '3am',
    '4am',
    '5am',
    '6am',
    '7am',
    '8am',
    '9am',
    '10am',
    '11am',
    '12pm',
    '1pm',
    '2pm',
    '3pm',
    '4pm',
    '5pm',
    '6pm',
    '7pm',
    '8pm',
    '9pm',
    '10pm',
    '11pm',
  ];

  return {
    tooltip: {
      position: 'top',
      formatter: (_params: any) => {
        return (
          converToNumberFormat(_params.value[2]) +
          ' in ' +
          hours[_params.value[0]] +
          ' of ' +
          yAxisData[_params.value[1]]
        );
      },
    },
    grid: {
      left: 10,
      top: 22,
      bottom: 25,
      right: 25,
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: hours,
      boundaryGap: false,
      splitLine: {
        show: true,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        margin: 22,
      },
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
      axisLine: {
        show: false,
      },
      axisLabel: {
        margin: 27,
      },
      // interval: 1,
    },
    series: [
      {
        name: 'Punch Card',
        type: 'scatter',
        symbolSize: (val: any) => {
          const minSize = 10;   // Minimum size for symbols
          const maxSize = 50;  // Maximum size for symbols

          const value = val[2];
          if (!value) {
            return 0;
          }
          const scaledSize = ((value - min) / (max - min)) * (maxSize - minSize) + minSize;

          // Ensure size is within minSize and maxSize limits
          return Math.max(minSize, Math.min(scaledSize, maxSize));

        },
        data,
        animationDelay: (idx: any) => {
          return idx * 5;
        },
      },
    ],
  };
};
