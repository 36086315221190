// import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCategory } from '../../actions/Categories';
import { closeDialogCategory } from '../../actions/Dialog';
import { saveStreams } from '../../actions/Stream';
import { ICategory, ICategoryDetail } from '../../sheard/interfaces/Category';
import { IStream } from '../../sheard/interfaces/Stream';
import { getStreams } from '../../sheard/services/StreamService';

interface IProps {
    closeCategory: typeof closeDialogCategory;
    categories: ICategory[];
    selectedCategory: typeof selectCategory;
    savedStreams: typeof saveStreams;
}

interface IState {
    category: {
        categories: ICategory[];
    };
}

class CategoriesDialog extends Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentWillMount() {
        document.addEventListener('click', this.handleOutsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    /**
     *
     *
     * @param {*} event
     * @memberof CategoriesDialog
     */
    public handleOutsideClick(event: any) {
        const elementDialog = document.getElementById('dialogCategory');
        if (elementDialog && !elementDialog.contains(event.target)) {
            this.props.closeCategory();
        }
    }

    /**
     *
     *
     * @param {ICategoryDetail} category
     * @memberof CategoriesDialog
     */
    public selected(category: ICategoryDetail) {
        this.props.selectedCategory(category);
        this.props.closeCategory();
        // debugger;
        // getStreams(category.permalink)
        //     .then((value: void | IStream[]) => {
        //         if (value) {
        //             this.props.savedStreams(value);
        //         }
        //     });
    }

    /**
     *
     *
     * @returns
     * @memberof CategoriesDialog
     */
    public renderList() {
        if (this.props.categories && this.props.categories.length > 0) {
            return this.props.categories[0].categories.map((category: any) => {
                return (
                    <li key={category.name} onClick={() => this.selected(category)}>
                        <a><i className={category.icon}></i><p>{category.name}</p></a>
                    </li>

                );
            });
        }
    }

    render() {
        if (this.props.categories) {
            return (
                <dialog className='category show-flex' id='dialogCategory' >
                    <section>
                        <header>
                            <h2>Categories</h2>
                        </header>
                        <ul>
                            {this.renderList()}
                        </ul>
                    </section>
                </ dialog>
            );
        }
    }
}

const mapStateToProps = (state: IState) => {
    return {
        categories: state.category.categories,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        closeCategory: closeDialogCategory,
        selectedCategory: selectCategory,
        savedStreams: saveStreams,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDialog);
