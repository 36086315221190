import React from 'react';
import { converToNumberFormat } from '../../../sheard/functions/numeral';
import EchartComponent from './Echart';

const NightingalChart = (props: { chartOption: INightingalChartOption }) => {
  const option = getOption(props.chartOption);
  return <EchartComponent options={option} />;
};

export default NightingalChart;

interface INightingalChartOption {
  data: any;
}

const getOption = (params: INightingalChartOption) => {
  return {
    legend: {
      top: 'bottom',
      tooltip: {
        show: true,
      },
      formatter(name: any) {
        if (name.length > 18) {
          return name.slice(0, 15) + '...';
        }
        return name;
      },
    },
    tooltip: {
      show: true,
      formatter(serie: any) {
        return `${serie.marker} ${serie.name}<span style="margin-right: 40px;"></span>${converToNumberFormat(serie.value)}`;
      },
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        // dataView: { show: true, readOnly: false },
        // restore: { show: true },
        // saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: 'Nightingale Chart',
        type: 'pie',
        // radius: [50, 150],
        radius: [30, 140],
        center: ['50%', '40%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 8,
        },
        data: params.data,
      },
    ],
  };
};
