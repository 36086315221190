import axios from 'axios';
import * as _ from 'lodash';
import { Api } from './../api/api';
import { IStream } from './../interfaces/Stream';

export function getStreams(typeCategory: string = '', sort_type: string = 'trend', page: number = 1): Promise<void | IStream[]> {
    return Api.post({
        path: '/api/stream',
        data: { typeCategory, sort_type, page },
    })
        .then((response: { status: number, data: { data: { items: IStream[] } } }) => {
            if (response.status === 200) {
                return response.data.data.items;
            } else {
                throw new Error('Error getCategories not status 200');
            }

        })
        .catch((error: any) => {
            // tslint:disable-next-line:no-console
            console.log(error);
            throw new Error(error);
        });
}

export function getStreamsWithNPUrl(typeCategory: string = '', sort_type: string = 'trend', page: number = 1): Promise<void | IStream[]> {
    return Api.post({
        path: '/api/stream',
        data: { typeCategory, sort_type, page },
    })
        .then((response: { status: number, data: { data: { items: IStream[] } } }) => {
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error('Error getCategories not status 200');
            }

        })
        .catch((error: any) => {
            // tslint:disable-next-line:no-console
            console.log(error);
            throw new Error(error);
        });
}
