import React from 'react';
import { colorCode } from '../../../sheard/functions/color-code';
import EchartComponent from './Echart';

const WordCloudChart = (props: { chartOption: IWordCloudChartOption }) => {
  const option = getOption(props.chartOption);
  return <EchartComponent options={option} />;
};

export default WordCloudChart;

interface IWordCloudChartOption {
  data: any;
}

const getOption = (params: IWordCloudChartOption) => {
  const dataColor = [
    colorCode.orange,
    colorCode.green,
    colorCode.purple,
    colorCode.red,
    colorCode.blue,
    colorCode.pink,
    colorCode.cyan,
    colorCode.dark_blue,
    colorCode.gray,
    colorCode.positive,
    colorCode.light_blue,
  ];
  return {
    tooltip: {},
    series: [
      {
        type: 'wordCloud',
        shape: 'diamond',
        // maskImage: maskImage,
        left: 'center',
        top: 'center',
        width: '100%',
        height: '100%',
        right: null,
        bottom: null,
        sizeRange: [12, 86],
        rotationRange: [0, 0],
        gridSize: 12,
        textStyle: {
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          color: () => {
            return dataColor[Math.floor(Math.random() * dataColor.length)];
          },
        },
        data: params.data,
      },
    ],
  };
};
